








































































































































































































import { CellGroup, Field, Switch, RadioGroup, Radio, Uploader, Button, Popup, Picker, Cell, Dialog, Toast, Icon, DatetimePicker } from "vant";
import LocationPicker from "@/components/picker/location-picker.vue";
import * as qiniu from "qiniu-js";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    profile: String,
    siteId: {
      type: [Number, String],
      default: 0
    }
  },
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Switch.name]: Switch,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Dialog.Component.name]: Dialog.Component,
    [Icon.name]: Icon,
    [DatetimePicker.name]: DatetimePicker,
    LocationPicker
  },
  data() {
    return {
      id: 0,
      siteName: "",
      businessName: "",
      businessId: 0,
      province: "",
      city: "",
      area: "",
      address: "",
      latitude: 0,
      longitude: 0,
      chargePayFee: 0,
      chargeServiceFee: 0,
      chargeMonthFee: 0,
      chargeRentDayFee: 0,
      chargeRentNightFee: 0,
      chargeRentStartTime: 0,
      chargeRefundMin: 1,
      chargeLegacyMode: 0,
      chargeMaxTime: 0,
      chargeNotifyEnable: false,
      chargePrepayLock: false,
      chargeShowKwhPrice: "",
      chargeShowServicePrice: "",
      replacePayFee: 0,
      replaceMonthMode: 0,
      replaceMonthFee: 0,
      imageKeys: "",
      isShow: 0,
      replacePayMode: "",
      chargePayMode: "",
      chargeRentMode: 0,
      chargeMonthMode: 0,
      chargePowerLevels: [] as { power: number; price: number }[],
      powerDialog: {
        show: false,
        index: 0,
        power: 0,
        price: 0,
        act: "",
        title: "",
        target: null as any
      },
      servicePhone: "",
      timeConfigList: [] as { startTime: string; endTime: string; chargePayFee: number; chargePowerLevels: { power: number; price: number }[] }[],

      chargeLegacyModeBoolean: false,
      isChargeTimeLimited: false,
      isShowBoolean: false,
      replaceOn: false,
      chargeOn: false,
      chargeRentOn: false,
      chargeMonthOn: false,
      timeOfUsePricing: false,

      showTimePicker: false,
      timePickerTarget: null as any,
      timePickerTargetKey: "",

      showLocationPicker: false,

      qiniuToken: "",
      fileList: [] as { file: File; url: string; key: string; isImage: boolean }[],
      businessPicker: {
        show: false,
        list: [],
        default: 0,
        loading: true
      },

      number: 1
    };
  },
  watch: {
    "businessPicker.show": "onBusinessPickerShow",
    chargePayMode: "onChargePayModeChange",
    isChargeTimeLimited: "onChargeTimeLimitChange"
  },
  created() {
    this.id = Number(this.siteId);
    this.getQiniuToken();
    this.getData().then(() => {
      // 一些默认值
      if (this.id === 0) {
        this.chargeRefundMin = 1;
      }

      // 预设
      if (this.id === 0 && this.profile) {
        const profile = this.profile;
        if (profile == "dianbao") {
          this.chargeOn = true;
          this.chargePayMode = "2";
          this.chargePayFee = 0.5;
          this.chargeLegacyMode = 1;
          this.chargeLegacyModeBoolean = true;
          this.chargeNotifyEnable = true;
          this.chargePrepayLock = false;
        }
      }
    });
  },
  methods: {
    getData() {
      return this.$axios.post("/api/manage/getSiteDetail", { siteId: this.id }).then(res => {
        const data = res.data.data;
        Object.assign(this.$data, data);

        this.replacePayMode = data.replacePayMode + "";
        if (data.replacePayMode == 1) {
          this.replacePayFee = data.replacePayFee / 100;
        }
        this.chargePayMode = data.chargePayMode + "";
        this.chargePayFee = data.chargePayFee / 100;
        this.chargeServiceFee = data.chargeServiceFee ? data.chargeServiceFee : 0;
        this.chargeMonthFee = data.chargeMonthFee / 100;
        this.chargeRentDayFee = data.chargeRentDayFee / 100;
        this.chargeRentStartTime = data.chargeRentStartTime / 3600;
        this.chargeRefundMin = data.chargeRefundMin / 100;
        this.chargeMaxTime = data.chargeMaxTime / 3600;

        this.isShowBoolean = Boolean(data.isShow);
        this.replaceOn = Boolean(data.replacePayMode);
        this.chargeOn = Boolean(data.chargePayMode);
        this.chargeRentOn = Boolean(data.chargeRentMode);
        this.chargeMonthOn = Boolean(data.chargeMonthMode);
        this.chargeLegacyModeBoolean = Boolean(data.chargeLegacyMode);
        this.chargeNotifyEnable = data.chargeNotifyEnable;
        this.chargePrepayLock = data.chargePrepayLock;
        this.chargeShowKwhPrice = data.chargeShowKwhPrice;
        this.chargeShowServicePrice = data.chargeShowServicePrice;
        this.isChargeTimeLimited = data.chargeMaxTime > 0;

        if (data.chargePowerLevels) {
          this.chargePowerLevels = data.chargePowerLevels.split("|").map((v: string) => {
            const vs = v.split(",");
            return {
              power: Number(vs[0]),
              price: Number(vs[1]) / 100
            };
          });
        } else {
          this.chargePowerLevels = [
            { power: 200, price: 0.25 },
            { power: 2200, price: 1 }
          ];
        }

        for (const image of data.imageList) {
          this.fileList.push({
            file: image.file, //undef
            url: "https://resource.letfungo.com/" + image.key,
            key: image.key,
            isImage: true
          });
        }

        if (data.timeConfigList && data.timeConfigList.length > 0) {
          this.timeConfigList = data.timeConfigList.map((v: any) => {
            return {
              startTime: v.startTime,
              endTime: v.endTime,
              chargePayFee: v.chargePayFee / 100,
              chargePowerLevels: v.chargePowerLevels.split("|").map((v: string) => {
                const vs = v.split(",");
                return {
                  power: Number(vs[0]),
                  price: Number(vs[1]) / 100
                };
              })
            };
          });
          this.timeOfUsePricing = true;
        } else {
          this.timeConfigList = [];
          this.timeOfUsePricing = false;
        }
      });
    },
    onChargePayModeChange(mode: number) {
      mode = Number(mode);
      if (mode > 0) {
        switch (mode) {
          case 1:
          case 6:
          case 2:
            if (this.chargePayFee < 0) {
              this.chargePayFee = 99;
            }
            break;
        }
      }
    },
    onChargeTimeLimitChange(isChargeTimeLimited: boolean) {
      if (isChargeTimeLimited) {
        if (this.chargeMaxTime == 0) {
          this.chargeMaxTime = 12;
        }
      } else {
        this.chargeMaxTime = 0;
      }
    },
    formatChargeServiceFee(value: number) {
      if (value < 0 || value > 100) {
        return this.chargeServiceFee;
      } else {
        return Math.round(value);
      }
    },
    save() {
      const data = {
        siteId: this.id,
        siteName: this.siteName,
        businessId: this.businessId,
        province: this.province,
        city: this.city,
        area: this.area,
        address: this.address,
        latitude: this.latitude,
        longitude: this.longitude,
        chargePayMode: this.chargePayMode,
        chargePayFee: Math.round(this.chargePayFee * 100),
        chargeServiceFee: this.chargeServiceFee ? this.chargeServiceFee : 0,
        chargeMonthMode: this.chargeMonthOn ? 1 : 0,
        chargeMonthFee: this.chargeMonthFee * 100,
        chargeRentMode: this.chargeRentOn ? 1 : 0,
        chargeRentDayFee: this.chargeRentDayFee * 100,
        chargeRentNightFee: this.chargeRentDayFee * 100,
        chargeRentStartTime: this.chargeRentStartTime * 3600,
        chargeRefundMin: this.chargeRefundMin * 100,
        chargeMaxTime: this.chargeMaxTime * 3600,
        chargeLegacyMode: this.chargeLegacyModeBoolean ? 1 : 0,
        chargePowerLevels: this.chargePowerLevels.map(v => [v.power, Math.floor(v.price * 100)].join(",")).join("|"),
        chargeNotifyEnable: this.chargeNotifyEnable,
        chargePrepayLock: this.chargePrepayLock,
        chargeShowKwhPrice: this.chargeShowKwhPrice,
        chargeShowServicePrice: this.chargeShowServicePrice,
        replacePayMode: this.replacePayMode,
        replacePayFee: this.replacePayMode == "1" ? this.replacePayFee * 100 : this.replacePayFee,
        replaceMonthMode: this.replaceMonthMode,
        replaceMonthFee: this.replaceMonthFee * 100,
        imageKeys: "",
        servicePhone: this.servicePhone,
        isShow: this.isShowBoolean ? 1 : 0,
        timeConfigList: this.timeOfUsePricing
          ? JSON.stringify(
              this.timeConfigList.map(v => {
                return {
                  startTime: v.startTime,
                  endTime: v.endTime,
                  chargePayFee: Math.round(v.chargePayFee * 100),
                  chargePowerLevels: v.chargePowerLevels.map(v => [v.power, Math.floor(v.price * 100)].join(",")).join("|")
                };
              })
            )
          : "[]"
      };
      const keys = [];
      for (const f of this.fileList) {
        if (!f.key) {
          Toast.fail("存在上传未成功的照片");
          return;
        }
        keys.push(f.key);
      }
      data.imageKeys = keys.join("|");
      if (!data.siteName) {
        Toast.fail("请填写站点名称");
        return;
      }
      if (!data.businessId) {
        Toast.fail("请选择运营商");
        return;
      }
      if (!data.latitude) {
        Toast.fail("请选择位置");
        return;
      }
      if (!data.address) {
        Toast.fail("请填写详细位置");
        return;
      }
      if (this.replaceOn) {
        if (data.replacePayMode == "0") {
          Toast.fail("请选择换电收费模式");
          return;
        }
        if (isNaN(Number(data.replacePayFee)) || Number(data.replacePayFee) < 0) {
          Toast.fail("请填写换电费用");
          return;
        }
        if (data.replacePayMode == "2" && Math.abs(data.replacePayFee - Math.floor(data.replacePayFee)) >= 0.01) {
          Toast.fail("电量收费不支持小数");
          return;
        }
      } else {
        data.replacePayMode = "0";
        data.replacePayFee = 0;
      }
      if (this.chargeOn) {
        if (data.chargePayMode == "0") {
          Toast.fail("请选择充电收费模式");
          return;
        }
        if (isNaN(Number(data.chargePayFee)) || Number(data.chargePayFee) < 0) {
          Toast.fail("请填写充电费用");
          return;
        }
        if (this.isChargeTimeLimited && data.chargeMaxTime <= 0) {
          Toast.fail("请填写有效的充电最大时间");
          return;
        }
      } else {
        data.chargePayMode = "0";
        data.chargePayFee = 0;
        data.chargeRefundMin = 100;
        data.chargeLegacyMode = 0;
      }
      if (this.chargeRentOn) {
        if (!data.chargeRentDayFee) {
          Toast.fail("请填写充电占用费用");
          return;
        }
      } else {
        data.chargeRentMode = 0;
        data.chargeRentStartTime = 0;
        data.chargeRentDayFee = 0;
      }
      if (this.chargeMonthOn) {
        if (!data.chargeMonthFee) {
          Toast.fail("请填写充电包月费用");
          return;
        }
      } else {
        data.chargeMonthMode = 0;
        data.chargeMonthFee = 0;
      }
      if (this.chargeServiceFee && this.chargeShowKwhPrice) {
        Toast.fail("服务费只能填写一种");
        return;
      }
      this.$axios.post("/api/manage/editSite", data).then(res => {
        Toast.success("保存成功");
        this.id = res.data.data.id;
      });
    },
    editPowerLevel(index = 0, target: any) {
      this.powerDialog.index = index;
      this.powerDialog.act = "edit";
      this.powerDialog.title = "修改" + this.getLevelName(index, target.length);
      this.powerDialog.target = target;
      const p = target[index];
      if (p) {
        this.powerDialog.power = p.power;
        this.powerDialog.price = p.price;
      }
      this.powerDialog.show = true;
    },
    addPowerLevel(index: number, target: any) {
      this.powerDialog.index = index;
      this.powerDialog.act = "insert";
      this.powerDialog.title = "插入第" + (index + 1) + "档";
      this.powerDialog.target = target;
      const p = target[index];
      if (p) {
        this.powerDialog.power = p.power + 100;
        this.powerDialog.price = p.price + 1;
      }
      this.powerDialog.show = true;
    },
    removePowerLevel(index: number, target: any) {
      target.splice(index, 1);
    },
    onConfirmPower() {
      this.powerDialog.show = false;
      const index = this.powerDialog.index;
      const p = {
        power: this.powerDialog.power,
        price: Math.floor(this.powerDialog.price * 100) / 100
      };
      if (p.power > 2200) {
        Toast.fail("功率不能大于最大功率2200W");
        return;
      }
      let previousP;
      let nextP;
      if (this.powerDialog.act == "insert") {
        // 添加
        previousP = this.powerDialog.target[index];
        nextP = this.powerDialog.target[index + 1];
      } else if (this.powerDialog.act == "edit") {
        // 编辑
        previousP = this.powerDialog.target[index - 1];
        nextP = this.powerDialog.target[index + 1];
      }
      if (previousP && p.power <= previousP.power) {
        Toast.fail("功率必须大于上一档");
        return;
      }
      if (nextP && p.power >= nextP.power) {
        Toast.fail("功率必须小于下一档");
        return;
      }
      if (this.powerDialog.act == "insert") {
        // 添加
        this.powerDialog.target.splice(index + 1, 0, {
          power: this.powerDialog.power,
          price: this.powerDialog.price
        });
      } else if (this.powerDialog.act == "edit") {
        // 编辑
        this.powerDialog.target[index] = p;
      }
    },
    /**
     * 图片上传
     */
    getQiniuToken() {
      this.$axios.post("/api/manage/getQiniuToken", {}).then(res => {
        this.qiniuToken = res.data.data;
      });
    },
    onPickFile(file: { file: File; status: string; key: string }) {
      if (Array.isArray(file)) {
        for (const f of file) {
          this.onPickFile(f);
        }
        return;
      }
      file.status = "uploading";
      const options = {
        quality: 0.92,
        noCompressIfLarger: true
      };
      qiniu.compressImage(file.file, options).then(data => {
        const observable = qiniu.upload(data.dist as File, file.key, this.qiniuToken);
        observable.subscribe({
          complete(res) {
            file.status = "done";
            file.key = res.key;
          },
          error() {
            file.status = "error";
          }
        });
      });
    },
    /**
     * 地图
     */
    onPickLocation(e: {
      latitude: number;
      longitude: number;
      detail: {
        address: string;
        area: string;
        city: string;
        province: string;
      };
    }) {
      this.latitude = e.latitude;
      this.longitude = e.longitude;
      this.area = e.detail.area;
      this.city = e.detail.city;
      this.province = e.detail.province;
      this.address = e.detail.address;
    },
    /**
     * 页面提示
     */
    showChargeRefundMinInfo() {
      const message = `
自动退款最小金额只适用于:
先付后充下的中断自动退款

退款金额总是最小退款金额的倍数
设置为0则不退款

例子1:
最小金额1元，应退金额0.5元，则不退款
例子2:
最小金额2元，应退金额5.1元，则退4元
例子3:
最小金额1元，应退金额5.1元，则退5元
`;
      Dialog.alert({
        message: message,
        messageAlign: "left"
      });
    },
    showChargeLegancyModeInfo() {
      const message = "如果硬件上设置了一元充电时间，则以硬件上的设置向用户收费";
      Dialog.alert({
        message: message,
        messageAlign: "left"
      });
    },
    showChargeServiceFee() {
      Dialog.alert({
        message: `服务费用只做展示用途，不涉及结算；设置范围在0-100%之间，且只能设置整数; 不可与其他服务费用同时设置`,
        messageAlign: "center"
      });
    },
    showChargeShowPrice() {
      Dialog.alert({
        message: `服务费用只做展示用途，不涉及结算；不可与其他服务费用同时设置`,
        messageAlign: "center"
      });
    },
    doShowTimePicker(target: any, key: string) {
      this.showTimePicker = true;
      this.timePickerTarget = target;
      this.timePickerTargetKey = key;
    },
    newTimeConfig() {
      this.timeConfigList.push({
        startTime: "",
        endTime: "",
        chargePayFee: 0,
        chargePowerLevels: [
          { power: 200, price: 0.25 },
          { power: 2200, price: 1 }
        ]
      });
    },
    onBusinessPickerShow(show: boolean) {
      if (show && this.businessPicker.loading) {
        this.$axios.post("/api/manage/getBusinessListForPick").then(res => {
          this.businessPicker.list = res.data.data;
          this.businessPicker.loading = false;
        });
      }
    },
    onPickBusiness(value: { id: number; name: string }) {
      this.businessId = value.id;
      this.businessName = value.name;
      this.businessPicker.show = false;
    },
    getLevelName(index: number, len: number) {
      if (index == 0) {
        return "基础档";
      } else if (index == len - 1) {
        return "最高档";
      } else {
        return "第" + index + "档";
      }
    }
  }
});
